@import "/src/styles/mixins/flexGrid.mixin.scss";

.show {
  display: none !important;
}
.sidebarWrapper {
  border: var(--border-primary-thick);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .navLinksWrapper {
    padding-top: 1rem;
  }
  .footerWrapper {
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: 2rem;
    margin-left: 1rem;
    .rightMenu {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      .adminLabel {
        color: #186aa5;
        font-size: 1.5rem;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 8rem;
      }
      .superAdLabel {
        color: #000000;
        font-size: 1rem;
      }
    }
  }
  .manageLogo {
    border: var(--border-primary);
    color: var(--text-ternary);
    margin: auto;
    height: 3rem;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
    .iconSet {
      display: grid;
      align-items: center;
      grid-template-columns: auto 1fr;
      padding: 0.2rem 1rem;
      .iconBorderSet {
        outline: none;
        border: var(--border-primary);
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--text-secondary);
        margin-right: 0.5rem;
        color: var(--text-ternary);
      }
      .link {
        // color: var(--text-ternary);
        font-weight: 700;
        border: none;
        background: none;
        color: var(--text-ternary);
        padding-right: 0.2rem;
        text-transform: capitalize;
        justify-content: left;
      }
      &.activeLink {
        background: var(--text-ternary);

        .link {
          color: var(--text-secondary);
        }
        // opacity: 1;
      }
    }
    .iconSet:hover {
      .link {
        color: var(--text-secondary);
      }
      background-color: var(--bg-ternary);
    }
  }
}

.buttonDiv {
  margin-top: 1rem;
  display: flex;
}

.button {
  padding: 1rem;
  margin: 1rem 4rem;
}

.label {
  padding-left: 1rem;
  color: aliceblue;
}

.dialogHeader {
  color: var(--text-secondary);
  text-align: center;
}

.dialogDeleteBody {
  text-align: center;
  font-size: 1.2rem;
}

.closeBtn {
  display: flex;
  justify-content: flex-end;
  color: var(--bg-ternary);
  padding: 0.3rem;
  cursor: pointer;
}

.shown {
  display: flex;
}
.hidden {
  display: none;
}
.closeBtn {
  display: none;
}

.logo {
  height: 3.5rem;
}

@media screen and (max-width: 1440px) {
  .sidebarWrapper {
    width: 300px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1000;
    background: var(--bg-body);
  }
  .shown {
    display: flex;
  }
  .hidden {
    display: none;
  }
  .closeBtn {
    display: flex;
  }
}

@media screen and (max-width: 480px) {
  .sidebarWrapper {
    width: 100%;
  }
}

// .blurBg {
//   z-index: 99;
//   backdrop-filter: blur(12.7px);
//   -webkit-backdrop-filter: blur(12.7px);
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }
