.headerWrapper1 {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  font-weight: 700;
  // padding: 1rem;
  font-size: 1.5rem;
  // padding-left: 2rem;
  /* margin-left: 1rem; */
  padding: 1rem 1rem 0rem 1rem;
  background-color: var(--bg-header);
  overflow: auto;
}
.iconSet {
  background-color: #397cad;
  // width: 100%;
  color: var(--text-secondary);
  // border: 1px solid white;
  margin-right: 0.3rem;
  white-space: nowrap;
  &.activeLink {
    background-color: var(--bg-primary);
    color: var(--text-ternary);
  }
}

.settleSubmit {
  margin-top: 1.4rem;
}

.actionBtn {
  border: none;
  border-radius: 0.2rem;
  width: auto;
  color: white;
  padding: 0.4rem;
  margin: 0.2rem 0.2rem;
  font-size: 0.9rem;
}

.tableBody {
  tbody > tr > td {
    padding: 0.5rem !important;
  }
}
.settlementWrapper {
  display: flex;
  align-items: center;
}
.headerWrapper2 {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  color: var(--text-ternary);
  font-weight: 700;
  margin: 1.5rem 1rem 0.8rem 1rem;
  // background-color: #b9e2ff;
  .datePickerWrapper {
    display: flex;
    align-items: center;
  }
}
.actionSet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  .iconSet {
    gap: 5px;
    background-color: var(--bg-search);
    color: var(--text-ternary);
    font-size: 0.9rem;
    padding: 0.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

.dateWrp {
  border: var(--border-primary);
  border-radius: 0.2rem;
  cursor: pointer;
  padding: 0.5rem;
  &:focus {
    outline: var(--border-primary);
  }
}

.label {
  color: var(--text-ternary);
}

.btnWrp {
  display: flex;
}
.row {
  max-width: 13rem;
  // position: relative;
  margin-right: 1rem;
  .label {
    // position: absolute;
    // top: -18px;
    color: var(--text-ternary);
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
}

.filterReportHeader {
  padding: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnSet {
  margin-right: 1rem;
}
.csvBtn {
  border-radius: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  color: white;
  background-color: var(--bg-ternary);
}

//Dialog scss

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  border-bottom: var(--border-grey-dull);
  .raceInfo {
    margin: 0;
    display: flex;
  }
  .tidWrapper {
    display: flex;
    justify-content: flex-end;
    color: var(--text-ternary);
    font-style: italic;
  }
}

.countryName {
  margin: 0;
  display: flex;
}

.icon {
  color: var(--text-ternary);
}
.headerContainer {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.bodyWrapper {
  width: 100%;
  .cardRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .raceInfo {
      display: flex;
      flex-direction: column;
      padding: 1rem 1.5rem;
      border-right: var(--border-grey-dull);
      white-space: nowrap;
    }
    border-bottom: var(--border-grey-dull);

    &:last-child {
      border-bottom: none;
    }
  }
}

.winningLabel {
  color: #686868;
  font-weight: 500;
}

.TIDLabel {
  margin-bottom: 0.3rem;
  font-weight: 800;
  color: #686868;
}

.TIDValue {
  color: var(--text-ternary);
  font-size: 0.8rem;
  font-weight: 500;
}

.alignRight {
  text-align: right;
}
.wagerName {
  font-size: 1rem;
  color: var(--text-ternary);
}
.statusWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0rem 1rem;
}
.label {
  margin-bottom: 0.3rem;
  font-weight: 800;
  color: #686868;
}
.cardBodyBack {
  background-color: var(--bg-ternary-dull);
}
.value {
  color: var(--text-ternary);
  font-size: 0.8rem;
  font-weight: 500;
}
.pnlLabel {
  text-align: right;
  // color: var(--text-ternary-dull);
  font-weight: 800;
  color: #686868;
}
.pnlValue {
  color: #098d1a;
  font-size: 1.2rem;
  font-weight: 600;
  text-shadow: 1px 1px 2px #a0a0a0;
}
.pnlNegativeValue {
  color: red;
  font-size: 1.2rem;
  font-weight: 600;
}
.middleCard {
  display: flex;
  flex-direction: column;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .raceInfo {
    margin-right: 0px !important;
  }
}
.cardInfo {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  padding: 1rem 0.5rem;
  border-right: var(--border-grey-dull);
  width: 100%;
  align-items: center;
}
.combinations {
  display: flex;
}
.winningValue {
  padding-left: 10px;
  color: #098d1a;
  font-size: 1.2rem;
  font-weight: 600;
  text-shadow: 1px 1px 2px #a0a0a0;
  margin-top: 0.2rem;
}
.winningNegativeValue {
  padding-left: 10px;
  color: red;
  font-size: 1rem;
  font-weight: 600;
}

.footer {
  padding: 0.5rem 0rem;
  color: var(--text-ternary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeBtnWrapper {
  margin-left: auto;
  margin-top: 0.5rem;
}
.menuIcon {
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterIcon {
  // width: 4rem !important;
  transition: 0.3s;
  width: 100%;
  height: 2rem;
  margin-right: 1rem;
  &:hover {
    // border: 1px solid;
    background-color: white;
    color: var(--bg-ternary);
  }
}

.filterWrp {
  display: flex;
  align-items: center;
}

.dateRow {
  display: flex;
}
.nameWrp {
  margin-left: 0.5rem;
}

.actualResult {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  // border-bottom: var(--border-grey-dull);
}

.cardHeader {
  background-color: var(--bg-ternary-dull);
  font-weight: 600;
  width: 100%;
  text-align: left;
  padding: 0.3rem;
  color: #686868;
}

.resultsBody {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table {
  width: 30%;
  border-collapse: collapse;
  margin-top: 0.5rem;
}

.grid1 {
  display: flex;
  align-items: center;
}

.heading {
  align-items: start;
  font-weight: 600;
  color: #686868;
  padding: 0.3rem;
  text-align: start;
}

.item {
  text-align: left;
  width: 80%;
  font-weight: 500;
  color: var(--bg-ternary);
  padding: 0.3rem;
}

@media screen and (max-width: 768px) {
  .headerWrapper2 {
    align-items: flex-start;
    flex-direction: column;

    // .datePickerWrapper {
    // }
  }

  .btnSet {
    margin-right: 0.25rem;
  }
  .actionSet {
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .actionSet {
    margin-top: 0;
  }

  .row {
    margin-bottom: 2rem;
  }

  .dateRow {
    flex-direction: column;
  }
}

.exportButton a {
  color: white;
}

@media screen and (max-width: 540px) {
  .cardRow {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
  }

  .raceInfo {
    border: var(--border-grey-dull);
    border-right: none;
    width: 100%;
  }

  .TIDValue {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 6rem;
  }

  .wagerName {
    font-size: 0.8rem;
  }

  .middleCard {
    padding: 0.5rem;
  }
}
